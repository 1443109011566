<template>
    <v-card flat>
        <v-toolbar color="info" flat>
            <v-toolbar-title>
                Welcome to DotaTheme.com
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12" class="subtitle-1">
                    <b>What is this random site you've stumbled across?</b>
                    <br>
                    Basically members of the WeLikeDota community like to do Theme Thursdays where lots of quirky drafts are made up pairing Dota heroes together with certain themes. This site has been created solely to oversee that.
                </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-3" align="center" justify="center">
                <v-col cols="12" md="6" offset-md="6" class="text-center text-md-right">
                    <v-text-field v-model="search" label="Search:" color="black" clearable clear-icon="fas fa-times" @click:clear="search = ''" class="pr-1">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <br>
            <v-row>
                <v-col cols="12" md="4" v-for="(theme, i) in filteredThemes" :key="i" class="entries" @click="openPage(theme)" style="cursor: pointer">
                    <div class="subtitle-1">{{theme.name}}</div>
                    <div class="caption">{{theme.description}}</div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data(){
        return {
            search: ""
        }
    },
    methods: {
        openPage(selected){
            this.$router.push(`/theme/${selected.id}`)
        }
    },
    computed: {
        filteredThemes(){
            if (this.search == "") {
                return this.getThemes
            }
            else {
                return this.getThemes.filter(theme => {
                    return theme.name.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        getThemes(){
            let approvedThemes = this.$store.getters.getThemes.filter(theme => theme.approved == "true")
            return approvedThemes.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()){
                    return 1
                }
                else {
                    return -1
                }
            })
        }
    }
}
</script>

<style scoped>
.entries:nth-child(odd){
    outline: 1px solid darkgray;
    background: #d7dae5;
}
.entries:nth-child(even){
    outline: 1px solid darkgray;
}
</style>